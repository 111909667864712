import React from "react"
import clsx from "clsx"

type Props = {
  className?: string
}

const Preloader: React.FC<Props> = ({ className }) => (
  <div className={clsx("text-center", className)}>
    <div
      className="spinner-border text-primary"
      role="status"
      style={{ width: "5rem", height: "5rem" }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

export default Preloader
