import { API_BASE_URL } from '../../constants';

type RequestProps = {
  baseUrl?: string;
  path: string;
  args: any;
  isDownload?: boolean;
};

/**
 * Performs API request given a specific url and arguments
 * @param baseUrl    {String}     Base URL
 * @param path       {String}     Url to be fetched from
 * @param args       {Object}     Fetch configuration (such as method, headers, req body, ...)
 * @param isDownload {Boolean}    Flag to determine whether request is for file download
 * @returns                       Response body
 */
const request = async ({
  baseUrl = API_BASE_URL as string,
  path,
  args,
  isDownload = false,
}: RequestProps) => {
  // Create fetch aborter and attach it to promise
  const controller = new AbortController();
  const { signal } = controller;
  // Set timeout for long pending fetch
  setTimeout(() => controller.abort(), 30000);
  // const promise = await fetch(baseUrl + path, { ...args, signal });
  const promise = await fetch(baseUrl + path, { ...args, signal });
  if (!promise.ok) {
    const errorBody = await promise?.json();
    return Promise.reject(errorBody);
  }

  // If file download
  if (isDownload) {
    const blob = await promise.blob();
    const csvData = new Blob([blob], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvData, 'data.csv');
    return {};
  }

  // If JSON response
  const body = await promise.json();
  return body;
};

export default request;
