import { Language } from '../../../constants';
import { District } from '../../../types/venueTypes';
import { query } from '../headers';
import request from '../request';

const districtFetch = {
  getDistricts: async (lang = 'fr' as Language): Promise<District[]> =>
    request({
      path: `/district/?lang=${lang}`,
      args: {
        headers: query(),
      },
    }),
};

export default districtFetch;
