import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CoockieBaner from './components/CoockieBaner';
import Footer from './components/Footer';
import { useAuth } from './context/auth-context';

// Lazy load private and public App components
const AuthenticatedApp = React.lazy(() => import('./routes/AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(
  () => import('./routes/UnauthenticatedApp'),
);

function App() {
  const {
    authData: { isAuth },
    initialize,
  } = useAuth();

  useEffect(() => {
    // Fetch data from browser cache if exists to initialize authentication
    (async () => {
      await initialize();
    })();
  }, []);

  return (
    <Router>
      {isAuth ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <CoockieBaner />
      <Footer />
    </Router>
  );
}

export default App;
