import React, { createContext, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EVENTS_PERSISTED_FILTERS_KEY,
  VENUES_PERSISTED_FILTERS_KEY,
  Language,
  USER_PERSISTED_AWAITING_APPROVAL,
  VENUES_PERSISTED_SESSION_KEY,
  EVENTS_PERSISTED_SESSION_KEY,
} from '../constants';
import apiClient from '../services/api';
import {
  AuthData,
  UserCreds,
  UserData,
  User,
  UserNewDTO,
} from '../types/userTypes';

const AuthContext = createContext<{
  authData: AuthData;
  logIn: ({ username, password }: UserCreds) => Promise<UserData | null>;
  logOut: () => Promise<boolean>;
  initialize: () => Promise<void>;
  saveUserData: (userData: UserData, userCreds: UserCreds) => void;
  signUp: (data: UserNewDTO, lang: Language) => Promise<User | null>;
  updateUser: (userData: UserData) => void;
}>({
  authData: {
    awaitingApproval: false,
    initialized: false,
    isAuth: false,
    user: null,
    error: null,
  },
  logIn: () => Promise.resolve(null),
  logOut: () => Promise.resolve(false),
  initialize: () => Promise.resolve(),
  saveUserData: () => {},
  signUp: () => Promise.resolve(null),
  updateUser: () => {},
});
const { Provider } = AuthContext;

const AuthProvider: React.FC = (props: any) => {
  const [authData, setAuthData] = useState<AuthData>({
    awaitingApproval: false,
    initialized: false,
    isAuth: false,
    user: null,
    error: null,
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /**
   * Store user details and basic auth credentials in local storage
   * to keep user logged in between page refreshes
   * @param userData  {UserData}    Basic set of user data (excluding password)
   * @param userCreds {UserCreds}   Username and password of user to be encrypted
   */
  function saveUserData(
    userData: UserData,
    { username = '', password = '' }: UserCreds,
  ) {
    // Login & password update
    if (username.length > 0 && password.length > 0) {
      window.localStorage.setItem(
        'user',
        JSON.stringify({
          ...userData,
          authData: window.btoa(`${username}:${password}`),
        }),
      );
      // Account update
    } else {
      const storedUser = window.localStorage.getItem('user');
      const encryptedAuthData =
        storedUser !== null ? JSON.parse(storedUser)?.authData : '';
      const decryptedAuthData =
        encryptedAuthData && encryptedAuthData.length > 0
          ? window.atob(encryptedAuthData)
          : '';
      const userPassword = decryptedAuthData.split(':')[1];

      window.localStorage.setItem(
        'user',
        JSON.stringify({
          ...userData,
          authData:
            username.length > 0
              ? window.btoa(`${username}:${userPassword}`)
              : encryptedAuthData,
        }),
      );
    }
  }

  /**
   * Updates user in authData (state)
   * @param userData {Object} User data to be updated
   */
  function updateUser(userData: UserData) {
    setAuthData({ ...authData, user: userData });
  }

  /**
   * Login method that logs user in and caches auth data
   * @param param0 {Object}   Contains username and password entered by user
   * @returns {Promise}       Resolved (with response) or rejected promise (with error)
   */
  async function logIn({ username = '', password = '' }: UserCreds) {
    try {
      const resp = await apiClient.user.getUser({ username, password });

      // Save to localStorage
      saveUserData(resp, { username, password });

      // Remove awaiting approval flag from cache
      window.localStorage.removeItem(USER_PERSISTED_AWAITING_APPROVAL);

      // Set state
      setAuthData({
        awaitingApproval: false,
        initialized: true,
        isAuth: true,
        user: resp,
        error: null,
      });

      return await Promise.resolve(resp);
      // TODO: Fix type for error: any
    } catch (error: any) {
      // Set error
      setAuthData({
        awaitingApproval: authData.awaitingApproval,
        initialized: true,
        isAuth: false,
        user: null,
        error: {
          message: t('login.errUnauthorized'),
          status: 401,
        },
      });

      return null;
    }
  }

  /**
   * Logout method that logs user out and empties browser cache
   * @returns {Promise}   Resolved promise
   */
  async function logOut() {
    try {
      queryClient.removeQueries();
      window.localStorage.removeItem('user');
      window.localStorage.removeItem(EVENTS_PERSISTED_FILTERS_KEY);
      window.localStorage.removeItem(VENUES_PERSISTED_FILTERS_KEY);
      window.sessionStorage.removeItem(VENUES_PERSISTED_SESSION_KEY);
      window.sessionStorage.removeItem(EVENTS_PERSISTED_SESSION_KEY);
    } catch (error) {
      console.warn(error);
    }
    setTimeout(() => {
      setAuthData({
        awaitingApproval: false,
        initialized: true,
        isAuth: false,
        user: null,
        error: null,
      });
    }, 500);
    return Promise.resolve(true);
  }

  /**
   * Initializes authentication by checking if auth data is in cache and updating auth context
   */
  async function initialize() {
    const lsUser = window.localStorage.getItem('user');
    const isAwaitingApproval = window.localStorage.getItem(
      USER_PERSISTED_AWAITING_APPROVAL,
    );
    const response = lsUser !== null ? JSON.parse(lsUser) : null;
    if (response) {
      setAuthData({
        awaitingApproval:
          (isAwaitingApproval && isAwaitingApproval === '1') || false,
        initialized: true,
        isAuth: true,
        user: response,
        error: null,
      });
    } else {
      setAuthData({
        awaitingApproval:
          (isAwaitingApproval && isAwaitingApproval === '1') || false,
        initialized: true,
        isAuth: false,
        user: null,
        error: null,
      });
    }
  }

  /**
   * Creates new account for user
   * @param data {UserNewDTO}  Signup form data
   * @returns                 Promise with response data
   */
  async function signUp(data: UserNewDTO, lang: Language) {
    try {
      // const response = await apiClient.user.signup(data);
      // return await Promise.resolve(response);
      const response = await apiClient.user.signup(data, lang);

      // store awaitingApproval flag in local storage
      // to remind user to check their mailbox
      localStorage.setItem(USER_PERSISTED_AWAITING_APPROVAL, '1');

      setAuthData({
        awaitingApproval: true,
        initialized: false,
        isAuth: false,
        user: null,
        error: null,
      });

      return await Promise.resolve(response);
      // TODO: Fix type for error: any
    } catch (error: any) {
      // Prepare error message
      let errMessage = '';
      if (error && error.msg) {
        if (error.msg === 'The passwords does not match') {
          errMessage = t('signup.errorUnmatchingPassword');
        } else if (error.msg === 'This email already exists') {
          errMessage = t('signup.errorAlreadyUsedEmail');
        }
      } else {
        errMessage = t('general.error');
      }

      // Set error
      setAuthData({
        awaitingApproval: false,
        initialized: false,
        isAuth: false,
        user: null,
        error: {
          message: errMessage,
          status: error?.status,
        },
      });

      throw Error(errMessage);
    }
  }

  // Data/methods accessible via the auth context
  const value = {
    authData,
    logIn,
    logOut,
    initialize,
    saveUserData,
    signUp,
    updateUser,
  };

  return <Provider value={value} {...props} />;
};

/**
 * Hook to access all data and methods from the auth context
 * @returns {Object}  { authData, logIn, logOut, initialize }
 */
function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { useAuth, AuthProvider };
