import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './auth-context';
import { UserPermissionsProvider } from './permissions-context';

type AppProvidersProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <UserPermissionsProvider>{children}</UserPermissionsProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default AppProviders;
