import { Language } from '../../../constants';
import { Address, Street, City } from '../../../types/venueTypes';
import { query } from '../headers';
import request from '../request';

const addressFetch = {
  getAddress: async ({
    lang,
    address,
  }: {
    lang: Language;
    address: string;
  }): Promise<Address[]> =>
    request({
      path: `/search/address?language=${lang}&address=${address}`,
      args: {
        headers: query(),
      },
    }),
  autocomplete: async ({
    lang,
    address,
  }: {
    lang: Language;
    address: string;
  }): Promise<Street[]> =>
    request({
      path: `/search/autocomplete?language=${lang}&partialStreet=${address}`,
      args: {
        headers: query(),
      },
    }),
    autocompleteCity: async ({
                             lang,
                             address,
                         }: {
        lang: Language;
        address: string;
    }): Promise<City[]> =>
        request({
            path: `/search/cities?language=${lang}&street=${address}`,
            args: {
                headers: query(),
            },
        }),
};

export default addressFetch;
