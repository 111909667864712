import { useEffect, useState } from 'react';

/**
 * Stores given data to localStorage
 * @param key {String}        Key under which data is stored
 * @param defaultValue {any}  Default value in case data hasn't been stored yet
 * @returns                   UseState stored data getter and setter
 */
const useLocalStorage = <T = any>(
  key: string,
  defaultValue?: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [item, setItem] = useState<T>(() => {
    const persistedItem = localStorage.getItem(key);
    return persistedItem
      ? { ...defaultValue, ...JSON.parse(persistedItem) }
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(item));
  }, [item, key]);

  return [item, setItem];
};

export default useLocalStorage;
