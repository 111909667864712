import { query } from '../headers';
import request from '../request';

const mediaFetch = {
  getYoutubeData: async (videoUrl: string) => {
    const reg =
      /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w-]{10,20})/i;
    const match = videoUrl.match(reg);
    let finalUrl = '';
    if (match) {
      // eslint-disable-next-line prefer-destructuring
      finalUrl = match[9];
    }
    return request({
      baseUrl: 'https://youtube.com',
      path: `/oembed?url=https://www.youtube.com/watch?v=${finalUrl}&format=json&maxwidth=500`,
      args: {},
    });
  },
  getVimeoData: async (videoUrl: string) =>
    request({
      baseUrl: 'https://vimeo.com/api',
      path: `/oembed.json?url=${videoUrl}&maxwidth=500`,
      args: {},
    }),
  getImageValidation: async (data: FormData) =>
    request({
      path: `/file/upload`,
      args: {
        method: 'POST',
        headers: query(),
        body: data,
      },
    }),
};

export default mediaFetch;
