import { PAGE_SIZE_IMPORTS } from '../../../constants';
import {
  ImportMappingElementDTO,
  ImportMappingFilteredDTO,
  ImportNewMappingDTO,
} from '../../../types/importTypes';
import { mutation, query } from '../headers';
import { ImportsParams } from '../model';
import request from '../request';

const importsFetch = {
  getImportOrigins: async (): Promise<string[]> =>
    request({
      path: '/imports/origins',
      args: {
        headers: query(),
      },
    }),
  fetch: {
    venues: async ({
      importOrigin,
      page = 0,
      size = PAGE_SIZE_IMPORTS,
    }: ImportsParams): Promise<ImportMappingFilteredDTO> =>
      request({
        path: `/imports/venues/${importOrigin}?size=${size}&page=${page}`,
        args: {
          headers: query(),
        },
      }),
    categories: async ({
      importOrigin,
      page = 0,
      size = PAGE_SIZE_IMPORTS,
    }: ImportsParams): Promise<ImportMappingFilteredDTO> =>
      request({
        path: `/imports/categories/${importOrigin}?size=${size}&page=${page}`,
        args: {
          headers: query(),
        },
      }),
  },
  create: {
    venues: async (
      data: ImportNewMappingDTO,
    ): Promise<ImportMappingElementDTO> =>
      request({
        path: '/imports/venues/create',
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
    categories: async (
      data: ImportNewMappingDTO,
    ): Promise<ImportMappingElementDTO> =>
      request({
        path: '/imports/categories/create',
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
  },
  update: {
    venues: async ({
      data,
      id,
    }: {
      data: ImportNewMappingDTO;
      id: number;
    }): Promise<ImportMappingElementDTO> =>
      request({
        path: `/imports/venues/${id}`,
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
    categories: async ({
      data,
      id,
    }: {
      data: ImportNewMappingDTO;
      id: number;
    }): Promise<ImportMappingElementDTO> =>
      request({
        path: `/imports/categories/${id}`,
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
  },
};

export default importsFetch;
