import { Language } from '../../../constants';
import {
  InstitutionUserLinkNewDTO,
  PageInstitutionUserLinkDTO,
  InstitutionUserLinkDTO,
  UserPosition,
} from '../../../types/userTypes';
import { mutation, query } from '../headers';
import request from '../request';

const userLinkFetch = {
  getLinkedVenues: async (
    userId: number,
  ): Promise<PageInstitutionUserLinkDTO> =>
    request({
      path: `/userLink/user/${userId}`,
      args: {
        headers: query(),
      },
    }),
  getFunctions: async (): Promise<UserPosition[]> =>
    request({
      path: '/userLink/functions',
      args: {
        headers: query(),
      },
    }),
  venue: {
    unlink: async (
      userId: number,
      data: InstitutionUserLinkNewDTO,
    ): Promise<InstitutionUserLinkDTO[]> =>
      request({
        path: `/userLink/user/${userId}/unlink`,
        args: {
          method: 'PATCH',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
    link: async (
      userId: number,
      data: InstitutionUserLinkNewDTO,
    ): Promise<InstitutionUserLinkDTO[]> =>
      request({
        path: `/userLink/user/${userId}/link`,
        args: {
          method: 'PATCH',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
  },
  searchLinkedVenue: async ({
    name,
    lang,
    userId,
  }: {
    name: string;
    lang: Language;
    userId: number;
  }): Promise<InstitutionUserLinkDTO[]> =>
    request({
      path: `/userLink/venuestolink/${userId}?name=${name}&lang=${lang}&limit=100`,
      args: {
        headers: query(),
      },
    }),
  searchLinkedVenueSignup: async ({
    name,
    lang,
  }: {
    name: string;
    lang: Language;
  }): Promise<InstitutionUserLinkDTO[]> =>
    request({
      path: `/userLink/venuestolink?name=${name}&lang=${lang}&limit=100`,
      args: {
        headers: query(),
      },
    }),
};

export default userLinkFetch;
