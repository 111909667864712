// TODO: Type all endpoints (arguments and returned value)

import addressFetch from './endpoints/address';
import cityFetch from './endpoints/city';
import districtFetch from './endpoints/district';
import eventFetch from './endpoints/event';
import importsFetch from './endpoints/imports';
import institutionFetch from './endpoints/institution';
import mediaFetch from './endpoints/media';
import miceFetch from './endpoints/mice';
import propertyFetch from './endpoints/property';
import tagFetch from './endpoints/tag';
import userFetch from './endpoints/user';
import userLinkFetch from './endpoints/userLink';
import usersFetch from './endpoints/users';

const apiClient = {
  address: addressFetch,
  city: cityFetch,
  district: districtFetch,
  event: eventFetch,
  imports: importsFetch,
  institution: institutionFetch,
  media: mediaFetch,
  property: propertyFetch,
  mice: miceFetch,
  user: userFetch,
  users: usersFetch,
  userLink: userLinkFetch,
  tags: tagFetch,
};

export default apiClient;
