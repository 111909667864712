import { PAGE_SIZE_USERS, SORT_BY_VALIDATION_KEY } from '../../../constants';
import {
  PageUserListDTO,
  UserDTO,
  UserListDTO,
  UserRefuseDTO,
  UsersStatusDTO,
} from '../../../types/userTypes';
import { mutation, query } from '../headers';
import { UsersParams } from '../model';
import request from '../request';

const usersFetch = {
  searchUsers: async (searchQuery: string): Promise<UserListDTO[]> =>
    request({
      path: `/user/search/users?value=${searchQuery}`,
      args: {
        headers: query(),
      },
    }),
  getUsers: async ({
    name,
    page = 0,
    size = PAGE_SIZE_USERS,
    sort = `${SORT_BY_VALIDATION_KEY},desc`,
  }: UsersParams): Promise<PageUserListDTO> =>
    request({
      path: `/user/search?size=${size}&page=${page}&value=${name}&sort=${sort}`,
      args: {
        headers: query(),
      },
    }),
  getStatus: async (): Promise<UsersStatusDTO> =>
    request({
      path: `/user/status`,
      args: {
        headers: query(),
      },
    }),
  show: async (id: number): Promise<UserDTO> =>
    request({
      path: `/user/${id}`,
      args: {
        headers: query(),
      },
    }),
  updateUserDetails: async (data: UserDTO): Promise<UserDTO> =>
    request({
      path: `/user`,
      args: {
        method: 'PUT',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  // TODO: UPDATE DATA TYPE + URL
  updateLinkedVenues: async (data: any) =>
    request({
      path: '/user/linkedVenues',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  activate: async (id: number) =>
    request({
      path: `/user/${id}/activate`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),

  validate: async (id: number) =>
    request({
      path: `/user/${id}/validate`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  deactivate: async (id: number, data: UserRefuseDTO) =>
    request({
      path: `/user/${id}/deactivate`,
      args: {
        method: 'PATCH',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
};

export default usersFetch;
