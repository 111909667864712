import {
  InstitutionAccessibility,
  InstitutionProperty,
} from '../../../types/venueTypes';
import { query } from '../headers';
import request from '../request';

const propertyFetch = {
  getAccessibility: async (): Promise<InstitutionAccessibility[]> =>
    request({
      path: `/accessibility`,
      args: {
        headers: query(),
      },
    }),
  getAllProperties: async (): Promise<InstitutionProperty[]> =>
    request({
      path: `/property/all`,
      args: {
        headers: query(),
      },
    }),
};

export default propertyFetch;
