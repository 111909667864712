import { InstitutionMiceDetailsCategories } from '../../../types/venueTypes';
import { query } from '../headers';
import request from '../request';

const miceFetch = {
  getAllMiceDetailsCategories:
    async (): Promise<InstitutionMiceDetailsCategories> =>
      request({
        path: '/mice/details',
        args: {
          headers: query(),
        },
      }),
};

export default miceFetch;
