import { UserCreds } from '../../types/userTypes';
import authHeader from '../auth-header';

export const mutation = (data?: UserCreds) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...authHeader(data),
});

export const query = (data?: UserCreds) => ({
  ...authHeader(data),
});
