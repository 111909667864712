import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Preloader from './components/Preloader';
import reportWebVitals from './reportWebVitals';
import AppProviders from './context';

/* Localisation */
import './i18n';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Preloader />}>
      <AppProviders>
        <App />
      </AppProviders>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
