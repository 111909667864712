import { QuickTagCategory, QuickTagDTO } from '../../../types/tagTypes';
import { mutation } from '../headers';
import request from '../request';

const tagFetch = {
  list: async (category: QuickTagCategory): Promise<QuickTagDTO[]> =>
    request({
      path: `/tag/${category}`,
      args: {
        method: 'GET',
        headers: mutation(),
      },
    }),
  listAll: async (): Promise<QuickTagDTO[]> =>
    request({
      path: '/tag/',
      args: {
        method: 'GET',
        headers: mutation(),
      },
    }),
  getById: (category: QuickTagCategory, id: string): Promise<QuickTagDTO> =>
    request({
      path: `/tag/${category}/${id}`,
      args: {
        method: 'GET',
        headers: mutation(),
      },
    }),
  create: (
    category: QuickTagCategory,
    body: QuickTagDTO,
  ): Promise<QuickTagDTO> =>
    request({
      path: `/tag/${category}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(body),
      },
    }),
  update: (
    category: QuickTagCategory,
    body: QuickTagDTO,
  ): Promise<QuickTagDTO> =>
    request({
      path: `/tag/${category}`,
      args: {
        method: 'PATCH',
        headers: mutation(),
        body: JSON.stringify(body),
      },
    }),
  remove: (category: QuickTagCategory, id: string) =>
    request({
      path: `/tag/${category}/${id}`,
      args: {
        method: 'DELETE',
        headers: mutation(),
      },
    }),
};

export default tagFetch;
