import React from "react";
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();

    const getPrivacyPolicyUrl = () => {
        if(i18n.language === 'en') {
            return 'https://www.visit.brussels/en/visitors/about-us/privacy-policy';
        }
        if(i18n.language === 'nl') {
            return 'https://www.visit.brussels/nl/bezoekers/over-ons/privacy-policy';
        }
        return 'https://www.visit.brussels/fr/visiteurs/a-propos/charte-de-vie-privee';
    }

    const getCookiePolicyUrl = () => {
        if(i18n.language === 'en') {
            return 'https://www.visit.brussels/en/visitors/about-us/cookies-policy';
        }
        if(i18n.language === 'nl') {
            return 'https://www.visit.brussels/nl/bezoekers/over-ons/gebriuk-van-cookies';
        }
        return 'https://www.visit.brussels/fr/visiteurs/a-propos/cookies';
    }

    const getGeneralTermsUrl = () => {
        if(i18n.language === 'en') {
            return 'https://www.visit.brussels/en/visitors/about-us/general-terms-and-conditions-of-use-of-the-website';
        }
        if(i18n.language === 'nl') {
            return 'https://www.visit.brussels/nl/bezoekers/over-ons/algemene-gebruiksvoorwaarden-voor-het-gebruik-van-de-website';
        }
        return 'https://www.visit.brussels/fr/visiteurs/a-propos/conditions-generales-d-utilisation-du-site-internet';
    }

    return (
      <footer className="footer mt-auto py-3 bg-dark text-white">
        <div className="container">
          &copy; {new Date().getFullYear()} Visit Brussels. All rights reserved.
            <a href={getPrivacyPolicyUrl()} aria-label={t('general.privacyPolicy')}> {t('general.privacyPolicy')}</a>
            <a href={getCookiePolicyUrl()} aria-label={t('general.cookiePolicy')}> {t('general.cookiePolicy')}</a>
            <a href={getGeneralTermsUrl()} aria-label={t('general.generalTermsConditions')}> {t('general.generalTermsConditions')}</a>
        </div>
      </footer>
    )
}

export default Footer
