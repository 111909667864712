import { Language, LanguageBasic } from '../../../constants';
import {
  UserCreds,
  UserData,
  User,
  UserNewDTO,
  UserPasswordDTO,
  UserDTO,
  UserUpdatedDTO,
  UserShortDTO,
} from '../../../types/userTypes';
import { mutation, query } from '../headers';
import request from '../request';

const userFetch = {
  login: async (data: UserCreds) =>
    request({
      path: '/login',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  getUser: async (data: UserCreds): Promise<UserData> =>
    request({
      path: '/user',
      args: {
        headers: query(data),
      },
    }),
  signup: async (data: UserNewDTO, lang: Language): Promise<User> =>
    request({
      path: `/user/new?language=${lang}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  changePassword: async (
    data: UserPasswordDTO,
    userId: number,
  ): Promise<UserDTO> =>
    request({
      path: `/user/${userId}`,
      args: {
        method: 'PUT',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  resetPassword: async (
    email: string,
    lang: LanguageBasic,
  ): Promise<UserUpdatedDTO> =>
    request({
      path: `/user/newpassword/${email}?language=${lang}`,
      args: {
        headers: mutation(),
      },
    }),
  updateAccount: async (data: UserShortDTO): Promise<UserShortDTO> =>
    request({
      path: '/user',
      args: {
        method: 'PATCH',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  deleteAccount: async (): Promise<UserDTO> =>
    request({
      path: '/user',
      args: {
        method: 'DELETE',
        headers: mutation(),
      },
    }),
  downloadData: async (): Promise<string> =>
    request({
      path: '/user/export',
      args: {
        headers: query(),
      },
      isDownload: true,
    }),
};

export default userFetch;
