import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../hooks/useLocalStorage';

const COOKIES_KEY = 'VB-cookies';

type CookiesSettings = {
  accepted: boolean;
};

function CoockieBaner() {
  const { t } = useTranslation();
  const [settings, setSettings] = useLocalStorage<CookiesSettings>(
    COOKIES_KEY,
    { accepted: false },
  );

  return (
    <>
      {!settings.accepted && (
        <Card className="fixed-bottom m-4">
          <Card.Header>{t('cookies.title')}</Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Card.Text>{t('cookies.text')}</Card.Text>
              </Col>
              <Col xs="auto">
                <Button
                  variant="primary"
                  onClick={() => setSettings({ accepted: true })}
                >
                  {t('general.ok')}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default CoockieBaner;
