import { Language } from '../../../constants';
import { InstitutionCity } from '../../../types/venueTypes';
import { query } from '../headers';
import request from '../request';

const cityFetch = {
  getCity: async ({
    lang,
    city,
  }: {
    lang: Language;
    city: string;
  }): Promise<InstitutionCity[]> =>
    request({
      path: `/city/search?language=${lang}&city=${city}`,
      args: {
        headers: query(),
      },
    }),
};

export default cityFetch;
