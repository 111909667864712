import { UserCreds } from '../types/userTypes';

/**
 * Returns authorisation headers (basic auth) based on cached user creds
 * @param data {Object}   [Optional] Credentials for initial login (username + password)
 * @returns {Object}      { 'Authorization': 'Basic ' + <cachedUserData> };
 */
export default function authHeader(data?: UserCreds) {
  // If first login, create auth header from scratch
  if (data) {
    const { username, password } = data;
    const encrypted = window.btoa(`${username}:${password}`);
    return { Authorization: `Basic ${encrypted}` }; // Basic auth
  }
  // If login creds are cached, use existing auth key
  const userStr = window.localStorage.getItem('user');
  if (userStr) {
    const user = JSON.parse(userStr);

    if (user && user.authData) {
      return { Authorization: `Basic ${user.authData}` }; // Basic auth
    }
  }

  return {};
}
