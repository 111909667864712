import React, { createContext, useState, useContext, useEffect } from 'react';
import { userRoles } from '../constants';
import { UserRoles } from '../types/userTypes';
import { useAuth } from './auth-context';

type UserPermissions = {
  [key: string]: boolean;
};

const UserPermissionsContext = createContext<any>({});
const { Provider } = UserPermissionsContext;

const UserPermissionsProvider: React.FC = (props: any) => {
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({});
  const {
    authData: { user },
  } = useAuth();

  useEffect(() => {
    setUserPermissions({});

    if (user) {
      const currentRoles: UserRoles[] = user?.authorities;

      if (currentRoles && currentRoles.length > 0) {
        const mappedPermissions = currentRoles.reduce(
          (acc: UserPermissions, curr) => {
            const permissions = userRoles[curr] || [];
            permissions.forEach((permission) => {
              acc[permission] = true;
            });
            return acc;
          },
          {},
        );

        setUserPermissions(mappedPermissions);
      }
    }
  }, [user]);

  // Data accessible via the user permissions context
  const value = {
    userPermissions,
  };

  return <Provider value={value} {...props} />;
};

/**
 * Hook to access all data from the user permissions context
 * @returns {Object}  { permissions }
 */
function useCurrentUserPermissions() {
  const context = useContext(UserPermissionsContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a UserPermissionsProvider`);
  }
  return context;
}

export { useCurrentUserPermissions, UserPermissionsProvider };
