import { PAGE_SIZE_VENUES, SORT_BY_UPDATE_KEY } from '../../../constants';
import {
  AccessibilityDTO,
  Institution,
  InstitutionAccessibilityLinkDTO,
  InstitutionGeneralInfoDTO,
  InstitutionMedia,
  InstitutionMergeDTO,
  InstitutionMiceDetailsCategories,
  InstitutionMiceRoom,
  InstitutionShortDTO,
  InstitutionUserLink,
  OpeningHoursUpdatePayload,
  PageInstitution,
} from '../../../types/venueTypes';
import { mutation, query } from '../headers';
import {
  AllVenuesParams,
  SearchParams,
  UsersLinkedParams,
  UserVenuesParams,
} from '../model';
import request from '../request';

const institutionFetch = {
  index: async ({
    page = 0,
    size = PAGE_SIZE_VENUES,
    sort = `${SORT_BY_UPDATE_KEY},desc`,
  }: AllVenuesParams): Promise<PageInstitution> =>
    request({
      path: `/institution/?size=${size}&page=${page}&sort=${sort}`,
      args: {
        headers: query(),
      },
    }),
  indexUser: async ({
    page = 0,
    size = PAGE_SIZE_VENUES,
    id,
    sort = `${SORT_BY_UPDATE_KEY},desc`,
  }: UserVenuesParams): Promise<PageInstitution> =>
    request({
      path: `/institution/user/${id}?size=${size}&page=${page}&sort=${sort}`,
      args: {
        headers: query(),
      },
    }),
  search: async ({
    fetchType = 'NEW_PUBLISHED',
    regionFetch = 'BRUSSELS_WALLONIA_FLANDERS',
    venueTypeFetch = '&venueTypeFetch=CULTURAL',
    lang,
    name,
    page = 0,
    size = PAGE_SIZE_VENUES,
    sort = `${SORT_BY_UPDATE_KEY},desc`,
  }: SearchParams): Promise<PageInstitution> =>
    request({
      path: `/institution/search?size=${size}&page=${page}&name=${encodeURIComponent(
        name,
      )}&lang=${lang}&fetchType=${fetchType}&regionFetch=${regionFetch}&sort=${sort}${venueTypeFetch}`,
      args: {
        headers: query(),
      },
    }),
  show: async (id: string) =>
    request({
      path: `/institution/${id}`,
      args: {
        headers: query(),
      },
    }),
  update: async ({ data, section = '' }: { data: any; section?: string }) =>
    request({
      path: `/institution/${section}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateGeneralInfo: async (data: any) =>
    request({
      path: '/generalInfo',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateProperties: async ({ data, id }: { data: { id: number }[]; id: string }) =>
    request({
      path: `/property/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateMedia: async ({ data, id }: { data: InstitutionMedia[]; id: string }) =>
    request({
      path: `/media/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateOpeningHours: async ({
    data,
    id,
  }: {
    data: OpeningHoursUpdatePayload;
    id: string;
  }) =>
    request({
      path: `/openingHours/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateMiceExtraDetails: async ({
    data,
    id,
  }: {
    data: InstitutionMiceDetailsCategories;
    id: string;
  }) =>
    request({
      path: `/mice/details/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateMiceRoom: async ({ data, id }: { data: InstitutionMiceRoom; id: string }) =>
    request({
      path: `/institutionRoom/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  deleteMiceRoom: async ({ data, id }: { data: InstitutionMiceRoom; id: string }) =>
    request({
      path: `/institutionRoom/${id}`,
      args: {
        method: 'DELETE',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  linkedUsers: async ({ id, lang }: UsersLinkedParams): Promise<InstitutionUserLink[]> =>
    request({
      path: `/institution/users/${id}?lang=${lang}`,
      args: {
        headers: query(),
      },
    }),
  // TODO: REFACTOR BELOW WITH EVENTS
  archive: async (id: number) =>
    request({
      path: `/institution/${id}/archive`,
      args: {
        method: 'PUT',
        headers: query(),
      },
    }),
  // TODO: REFACTOR BELOW WITH EVENTS
  unarchive: async (id: number) =>
    request({
      path: `/institution/${id}/unarchive`,
      args: {
        method: 'PUT',
        headers: query(),
      },
    }),
  validate: async (id: number) =>
    request({
      path: `/institution/${id}/validate`,
      args: {
        method: 'PUT',
        headers: query(),
      },
    }),
  create: {
    long: async (data: InstitutionGeneralInfoDTO) =>
      request({
        path: '/institution/new',
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
    short: async (data: InstitutionShortDTO): Promise<Institution> =>
      request({
        path: '/institution/short',
        args: {
          method: 'POST',
          headers: mutation(),
          body: JSON.stringify(data),
        },
      }),
  },
  merge: async (data: InstitutionMergeDTO): Promise<string> =>
    request({
      path: '/institution/merge',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  accessiblity: async (data: AccessibilityDTO, id: string): Promise<any> =>
    request({
      path: `/institution/audience/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  linkedAccessiblities: async (id: number): Promise<InstitutionAccessibilityLinkDTO[]> =>
    request({
      path: `/institution/${id}/accessibilities`,
      args: {
        method: 'GET',
        headers: query(),
      },
    }),
  importedInstitution: {
    lock: async (id: number): Promise<Institution> =>
      request({
        path: `/institution/${id}/lock`,
        args: {
          method: 'PATCH',
          headers: query(),
        },
      }),
    unlock: async (id: number): Promise<Institution> =>
      request({
        path: `/institution/${id}/unlock`,
        args: {
          method: 'PATCH',
          headers: query(),
        },
      }),
  },
};

export default institutionFetch;
